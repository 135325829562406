import { Change, Destroy, Required } from "./formReducers";

export const formChange = (name, value) => {
    return Change({
        name,
        value
    })
};
export const formDestroy = () => {
    return Destroy();
};
export const formRequired = (value) => {
    return Required({
        value
    })
};