import DefaultLayout from "layout/DefaultLayout";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useRedux from "redux/useRedux";
import "./App.css"
import "./assets/fonts/Pragmatica_36336.ttf"

const App = () => {
    const {
        global
    } = useRedux();

    if (global.isLogin === false && global.detailLogin === null) {
        return <Navigate replace to="/login" />
    }

    return (
        <>
            <DefaultLayout>
                <Outlet />
            </DefaultLayout>
        </>
    )
}
export default App;