import { SwalConfirmThen } from "components/reusable/Alert/SweetAlert";
import { clearCookie } from "utils/Cookies";
import { ERRORRESPONSE, ISLOADER, ISLOGIN, LOGINRESULT, RESETDATATABLES, RESETERRORRESPONSE, RESETGLOBAL, SETDATATABLES, SETSIDEBAR } from "./globalTypes";

export const setLoader = (bool) => {
    return {
        type: ISLOADER,
        payload: bool,
    };
};
export const setLogin = (bool) => {
    return {
        type: ISLOGIN,
        payload: bool,
    };
};
export const setErrorResponse = (payload) => {
    return {
        type: ERRORRESPONSE,
        payload: payload,
    };
};
export const resetErrorResponse = () => {
    return {
        type: RESETERRORRESPONSE,
    };
};
export const loginResult = (payload) => {
    return {
        type: LOGINRESULT,
        payload,
    };
}
export const setSidebar = (payload) => {
    return {
        type: SETSIDEBAR,
        payload,
    };
};
export const resetGlobal = () => {
    return {
        type: RESETGLOBAL,
    };
};
export const setDatatables = (payload) => {
    return {
        type: SETDATATABLES,
        payload,
    };
};
export const resetDatatables = () => {
    return {
        type: RESETDATATABLES,
    };
};

export const logoutClick = () => async (dispatch) => {
    const logouted = async () => {
        await clearCookie('rememberMe');
        await clearCookie('detailLogin');
        await dispatch(setLogin(false))
        await dispatch(loginResult(null))
    }

    try {
        SwalConfirmThen({
            callback: logouted
        })
    } catch (error) {
        console.error(error.message);
        // return dispatch(setError(error.message));
    }
}