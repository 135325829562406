import { CButton, CCol, CForm, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import RenderError from "../RenderError";
import { formChange } from "redux/form/formActions";
import { SwalError } from "../Alert/SweetAlert";
import useRedux from "redux/useRedux";

const Form = (props) => {
    const {
        children,
        onSubmit,
        hrefCancel,
        customButton,
    } = props;

    const {
        dispatch,
        form: {
            field,
            requiredField
        }
    } = useRedux();

    const [requiredProps, setRequiredProps] = useState([])
    const [errorProps, seterrorProps] = useState("")
    let temp = []
    useEffect(() => {
        for (const key in Form.propTypes) { //eslint-disable-line react/forbid-foreign-prop-types
            if (!Form.propTypes[key].isRequired) { //eslint-disable-line react/forbid-foreign-prop-types
                temp.push(key)
            }
        }
        if (temp.length > 0) {
            setRequiredProps(temp)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (requiredProps.length > 0) {
            for (const key in requiredProps) {
                if (!props[requiredProps[key]]) {
                    seterrorProps(requiredProps[key]);
                    break;
                }
            }
        }
    }, [requiredProps]); //eslint-disable-line react-hooks/exhaustive-deps

    const submit = async (e) => {
        e.preventDefault();
        let tampungError = []
        requiredField.map(item => {
            if (!field[item] || field[item] === "" || field[item] === null || field[item] === undefined) {
                dispatch(formChange(`err${item}`, true))
                tampungError.push(item)
            }
            return item
        }) // eslint-disable-line array-callback-return
        console.log('formRequired', tampungError)
        if (tampungError.length > 0) {
            return SwalError({
                text: `Please enter an required field`
            })
        } else {
            return onSubmit(e);
        }

    }

    if (errorProps) {
        return <RenderError component="Form" err={errorProps} />
    }

    return (
        <>
            <CForm onSubmit={submit}>
                {children}
                {
                    !customButton ? (
                        <CRow>
                            <CCol>
                                <CButton className="me-4" component="a" color="warning" variant="outline" href={hrefCancel} role="button">
                                    Cancel
                                </CButton>
                                <CButton color="primary" variant="outline" type="submit">Save</CButton>
                            </CCol>
                        </CRow>
                    ) : (
                        <>
                            {customButton}
                        </>
                    )
                }
            </CForm>
        </>
    )
}
Form.defaultProps = {
    hrefCancel: '/'
};

Form.propTypes = {
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    hrefCancel: PropTypes.string,
    customButton: PropTypes.any
}
export default Form;