import axios from "axios";
import { setDatatables } from "redux/global/globalActions";
import useRedux from "redux/useRedux";
import { getCookie } from "utils/Cookies";
import { uAPI } from "./config";

const UrlEncodedKey = (url = "", data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(uAPI + url, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

const AjaxDt = (url = "") => {
  let key = getCookie("ApiKey");
  const { dispatch } = useRedux();
  return {
    url: uAPI + url,
    type: "POST",
    headers: {
      Authorization: process.env.REACT_APP_ApiKey,
    },
    dataSrc: function (json) {
      //Make your callback here.
      console.log("dataSrc", json);
      dispatch(setDatatables(json));
      return json.data;
    },
  };
};

const ApiPost = {
  actions: {
    Login(data) {
      return UrlEncodedKey("/cms/auth", data);
    },
    SlideShow(data) {
      return UrlEncodedKey("/cms/slideshow", data);
    },
    DtSlideShow(data) {
      return AjaxDt("/cms/slideshow/dt", data);
    },
    Footer(data) {
      return UrlEncodedKey("/cms/footer", data);
    },
    AnakPerusahaan(data) {
      return UrlEncodedKey("/cms/anak-perusahaan", data);
    },
    DtAnakPerusahaan(data) {
      return AjaxDt("/cms/anak-perusahaan/dt", data);
    },
    TentangKami(data) {
      return UrlEncodedKey("/cms/tentang-kami", data);
    },
    DtTentangKami(data) {
      return AjaxDt("/cms/tentang-kami/dt", data);
    },
    Testimoni(data) {
      return UrlEncodedKey("/cms/testimoni", data);
    },
    DtTestimoni(data) {
      return AjaxDt("/cms/testimoni/dt", data);
    },
    DtQuotes(data) {
      return AjaxDt("/cms/quotes/dt", data);
    },
    Quotes(data) {
      return UrlEncodedKey("/cms/quotes", data);
    },
    DtMitra(data) {
      return AjaxDt("/cms/mitra/dt", data);
    },
    Mitra(data) {
      return UrlEncodedKey("/cms/mitra", data);
    },
    OurGroup(data) {
      return UrlEncodedKey("/cms/our-group", data);
    },
    DtOurGroup(data) {
      return AjaxDt("/cms/our-group/dt", data);
    },
    CoreValue(data) {
      return UrlEncodedKey("/cms/core-value", data);
    },
    DtCoreValue(data) {
      return AjaxDt("/cms/core-value/dt", data);
    },
    CoreValueDetail(data) {
      return UrlEncodedKey("/cms/core-value-detail", data);
    },
    DtCoreValueDetail(data) {
      return AjaxDt("/cms/core-value-detail/dt", data);
    },
    Komisaris(data) {
      return UrlEncodedKey("/cms/manajemen/komisaris", data);
    },
    DtKomisaris(data) {
      return AjaxDt("/cms/manajemen/komisaris/dt", data);
    },
    Direksi(data) {
      return UrlEncodedKey("/cms/manajemen/direksi", data);
    },
    DtDireksi(data) {
      return AjaxDt("/cms/manajemen/direksi/dt", data);
    },
    StrukturOrganisasi(data) {
      return UrlEncodedKey("/cms/struktur-organisasi", data);
    },
    DtStrukturOrganisasi(data) {
      return AjaxDt("/cms/struktur-organisasi/dt", data);
    },
    Sertifikasi(data) {
      return UrlEncodedKey("/cms/awards", data);
    },
    DtSertifikasi(data) {
      return AjaxDt("/cms/awards/dt", data);
    },
    HubunganInvestor(data) {
      return UrlEncodedKey("/cms/laporan", data);
    },
    DtHubunganInvestor(data) {
      return AjaxDt("/cms/laporan/dt", data);
    },
    Publikasi(data) {
      return UrlEncodedKey("/cms/publikasi", data);
    },
    DtPublikasi(data) {
      return AjaxDt("/cms/publikasi/dt", data);
    },
    Tjsl(data) {
      return UrlEncodedKey("/cms/tjsl", data);
    },
    DtTjsl(data) {
      return AjaxDt("/cms/tjsl/dt", data);
    },
    JenisLayanan(data) {
      return UrlEncodedKey("/cms/layanan/jenis-layanan", data);
    },
    DtJenisLayanan(data) {
      return AjaxDt("/cms/layanan/jenis-layanan/dt", data);
    },
    Layanan(data) {
      return UrlEncodedKey("/cms/layanan", data);
    },
    DtLayanan(data) {
      return AjaxDt("/cms/layanan/dt", data);
    },
    SlideshowLayanan(data) {
      return UrlEncodedKey("/cms/layanan/slideshow", data);
    },
    DtSlideshowLayanan(data) {
      return AjaxDt("/cms/layanan/slideshow/dt", data);
    },
    PagesGcg(data) {
      return UrlEncodedKey("/cms/gcg/pages", data);
    },
    DtPagesGcg(data) {
      return AjaxDt("/cms/gcg/pages/dt", data);
    },
    FileGcg(data) {
      return UrlEncodedKey("/cms/gcg/file", data);
    },
    DtFileGcg(data) {
      return AjaxDt("/cms/gcg/file/dt", data);
    },
    Gcg(data) {
      return UrlEncodedKey("/cms/gcg", data);
    },
    DtGcg(data) {
      return AjaxDt("/cms/gcg/dt", data);
    },

    PagesPpid(data) {
      return UrlEncodedKey("/cms/ppid/menu", data);
    },
    DtPagesPpid(data) {
      return AjaxDt("/cms/ppid/menu/dt", data);
    },
    DtPagesPpidSubmenu(data) {
      return AjaxDt("/cms/ppid/submenu/dt", data);
    },
    FilePpid(data) {
      return UrlEncodedKey("/cms/ppid/file", data);
    },
    TitlePpid(data) {
      return UrlEncodedKey("/cms/ppid/title", data);
    },
    DtFilePpid(data) {
      return AjaxDt("/cms/ppid/file/dt", data);
    },
    SubmenuPpid(data) {
      return UrlEncodedKey("/cms/ppid/submenu", data);
    },
    DtSubmenuPpid(data) {
      return AjaxDt("/cms/ppid/submenu/dt", data);
    },
    Ppid(data) {
      return UrlEncodedKey("/cms/ppid", data);
    },
    DtPpid(data) {
      return AjaxDt("/cms/ppid/dt", data);
    },

    Wilayah(data) {
      return UrlEncodedKey("/cms/fasilitas/wilayah", data);
    },
    DtWilayah(data) {
      return AjaxDt("/cms/fasilitas/wilayah/dt", data);
    },
    JenisFasilitas(data) {
      return UrlEncodedKey("/cms/fasilitas/jenis", data);
    },
    DtJenisFasilitas(data) {
      return AjaxDt("/cms/fasilitas/jenis/dt", data);
    },
    CategoryFasilitas(data) {
      return UrlEncodedKey("/cms/fasilitas/category", data);
    },
    DtCategoryFasilitas(data) {
      return AjaxDt("/cms/fasilitas/category/dt", data);
    },
    DetailFasilitas(data) {
      return UrlEncodedKey("/cms/fasilitas/detail", data);
    },
    DtDetailFasilitas(data) {
      return AjaxDt("/cms/fasilitas/detail/dt", data);
    },
    CategoryPublikasi(data) {
      console.log(data, "from add");
      return UrlEncodedKey("/cms/publikasi/category", data);
    },
    DtCategoryList(data) {
      console.log(data, "dt");
      return AjaxDt("/cms/publikasi/category/dt", data);
    },
    DtKontakKami(data) {
      return AjaxDt("/cms/contact-us/dt", data);
    },
    CategoryHubunganInvestor(data) {
      return UrlEncodedKey("/cms/laporan/category", data);
    },
    DtCategoryHubunganInvestor(data) {
      return AjaxDt("/cms/laporan/category/dt", data);
    },
    WilayahAnakPerusahaan(data) {
      return UrlEncodedKey("/cms/anak-perusahaan/wilayah", data);
    },
    DtWilayahAnakPerusahaan(data) {
      return AjaxDt("/cms/anak-perusahaan/wilayah/dt", data);
    },
    Popup(data) {
      return UrlEncodedKey("/cms/popup", data);
    },
    DtPopup(data) {
      return AjaxDt("/cms/popup/dt", data);
    },
    Popup(data) {
      return UrlEncodedKey("/cms/popup", data);
    },
    FooterUrlImg(data) {
      return UrlEncodedKey("/cms/footer-url-img", data);
    },
    DtFooterUrlImg(data) {
      return AjaxDt("/cms/footer-url-img/dt", data);
    },
  },
};

export default ApiPost;
