import axios from "axios";
import { clientKey, uAPI } from "./config";

const getApiKey = (url = "", params) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(uAPI + url, {
        params,
        credentials: 'include',
        headers: {
          client_key: `${clientKey}`,
        },
      })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};
const UrlEncodedKey = (url = "", config) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(uAPI + url, config,{ withCredentials: true })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
};

const ApiGet = {
  actions: {
    GetApiKey(params) {
      return getApiKey("/GetApiKey", params);
    },
    SlideshowDetail(id, params) {
      return UrlEncodedKey(`/cms/slideshow/${id}`, params);
    },
    Footer(data) {
      return UrlEncodedKey("/cms/footer", data);
    },
    FooterUrlImgDetail(id, params) {
      return UrlEncodedKey(`/cms/footer-url-img/${id}`, params);
    },
    AnakPerusahaanDetail(id, params) {
      return UrlEncodedKey(`/cms/anak-perusahaan/${id}`, params);
    },
    TentangKamiDetail(id, params) {
      return UrlEncodedKey(`/cms/tentang-kami/${id}`, params);
    },
    TestimoniDetail(id, params) {
      return UrlEncodedKey(`/cms/testimoni/${id}`, params);
    },
    QuotesDetail(id, params) {
      return UrlEncodedKey(`/cms/quotes/${id}`, params);
    },
    MitraDetail(id, params) {
      return UrlEncodedKey(`/cms/mitra/${id}`, params);
    },
    OurGroupDetail(id, params) {
      return UrlEncodedKey(`/cms/our-group/${id}`, params);
    },
    CoreValueData(id, params) {
      return UrlEncodedKey(`/cms/core-value/view`, params);
    },
    CoreValue(id, params) {
      return UrlEncodedKey(`/cms/core-value/${id}`, params);
    },
    CoreValueDetail(id, params) {
      return UrlEncodedKey(`/cms/core-value-detail/${id}`, params);
    },
    // Manajemen(id, params) {
    //     return UrlEncodedKey(`/cms/`)
    // },
    KomisarisDetail(id, params) {
      return UrlEncodedKey(`/cms/manajemen/komisaris/${id}`, params);
    },
    DireksiDetail(id, params) {
      return UrlEncodedKey(`/cms/manajemen/direksi/${id}`, params);
    },
    StrukturOrganisasiDetail(id, params) {
      return UrlEncodedKey(`/cms/struktur-organisasi/${id}`, params);
    },
    SertifikasiDetail(id, params) {
      return UrlEncodedKey(`/cms/awards/${id}`, params);
    },
    HubunganInvestorDetail(id, params) {
      return UrlEncodedKey(`/cms/laporan/${id}`, params);
    },
    Publikasi(id, params) {
      return UrlEncodedKey(`/cms/publikasi/${id}`, params);
    },
    TjslDetail(id, params) {
      return UrlEncodedKey(`/cms/tjsl/${id}`, params);
    },
    JenisLayananDetail(id, params) {
      return UrlEncodedKey(`/cms/layanan/jenis-layanan/${id}`, params);
    },
    LayananDetail(id, params) {
      return UrlEncodedKey(`/cms/layanan/${id}`, params);
    },
    JenisLayananOpt(id, params) {
      return UrlEncodedKey(`/cms/layanan/jenis-layanan/opt`, params);
    },
    SlideshowLayananDetail(id, params) {
      return UrlEncodedKey(`/cms/layanan/slideshow/${id}`, params);
    },
    LayananOpt(id, params) {
      return UrlEncodedKey(`/cms/layanan/opt`, params);
    },
    PagesGcgDetail(id, params) {
      return UrlEncodedKey(`/cms/gcg/pages/${id}`, params);
    },
    PagesGcgOpt(id, params) {
      return UrlEncodedKey(`/cms/gcg/pages/opt`, params);
    },
    FileGcgDetail(id, params) {
      return UrlEncodedKey(`/cms/gcg/file/${id}`, params);
    },
    GcgDetail(id, params) {
      return UrlEncodedKey(`/cms/gcg/${id}`, params);
    },

    PagesPpidDetail(id, params) {
      return UrlEncodedKey(`/cms/ppid/menu/${id}`, params,{ withCredentials: true });
    },
    PagesPpidOpt(id, params) {
      return UrlEncodedKey(`/cms/ppid/menu/opt`, params,{ withCredentials: true });
    },
    SubmenuPpidOpt(id, params) {
      return UrlEncodedKey(`/cms/ppid/submenu/opt`, params,{ withCredentials: true });
    },
    TitlePpidOpt(id, params) {
      return UrlEncodedKey(`/cms/ppid/title/opt`, params,{ withCredentials: true });
    },
    FilePpidDetail(id, params) {
      return UrlEncodedKey(`/cms/ppid/file/${id}`, params,{ withCredentials: true });
    },
    SubmenuPpidDetail(id, params) {
      return UrlEncodedKey(`/cms/ppid/menu/${id}`, params,{ withCredentials: true });
    },
    PpidDetail(id, params) {
      return UrlEncodedKey(`/cms/ppid/${id}`, params,{ withCredentials: true });
    },
    
    WilayahDetail(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/wilayah/${id}`, params);
    },
    WilayahOpt(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/wilayah/opt`, params);
    },
    JenisFasilitasDetail(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/jenis/${id}`, params);
    },
    JenisFasilitasOpt(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/jenis/opt`, params);
    },
    CategoryFasilitasDetail(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/category/${id}`, params);
    },
    CategoryFasilitasOpt(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/category/opt?id_jenis=${id}`, params);
    },
    DetailFasilitasDetail(id, params) {
      return UrlEncodedKey(`/cms/fasilitas/detail/${id}`, params);
    },
    CategoryPublikasi(id, params) {
      return UrlEncodedKey(`/cms/publikasi/category/${id}`, params);
    },
    CategoryHubunganInvestorDetail(id, params) {
      return UrlEncodedKey(`/cms/laporan/category/${id}`, params);
    },
    CategoryHubunganInvestorOpt(id, params) {
      return UrlEncodedKey(`/cms/laporan/category/opt`, params);
    },
    CategoryPublikasiOpt(id, params) {
      return UrlEncodedKey(`/cms/publikasi/category/opt`, params);
    },
    WilayahAnakPerusahaanDetail(id, params) {
      return UrlEncodedKey(`/cms/anak-perusahaan/wilayah/${id}`, params);
    },
    AnakPerusahaanOpt(id, params) {
      return UrlEncodedKey(`/cms/anak-perusahaan/opt`, params);
    },
    PopupDetail(id, params) {
      return UrlEncodedKey(`/cms/popup/${id}`, params);
    },
  },
};

export default ApiGet;
