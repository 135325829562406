import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavGroup,
    name: "Landing Page",
    to: "/",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem,
      //   name: 'Header Menu',
      //   to: '/header-menu',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Sub Header Menu',
      //   to: '/sub-header-menu',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Pages',
      //   to: '/pages',
      // },
      {
        component: CNavGroup,
        name: "Beranda Home",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Slideshow",
            to: "/slideshow",
          },
          {
            component: CNavItem,
            name: "Pesan BOD",
            to: "/quotes",
          },
          {
            component: CNavItem,
            name: "Testimoni",
            to: "/testimoni",
          },
          {
            component: CNavItem,
            name: "Pop Up",
            to: "/popup",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Profil",
        to: "/",
        items: [
          {
            component: CNavGroup,
            name: "Tentang Kami",
            to: "/tentang-kami",
            items: [
              {
                component: CNavItem,
                name: "Tentang Kami",
                to: "/tentang-kami",
              },
              {
                component: CNavItem,
                name: "Core Value",
                to: "/core-value",
              },
              {
                component: CNavItem,
                name: "Core Value Detail",
                to: "/core-value-detail",
              },
              {
                component: CNavItem,
                name: "Our Group",
                to: "/our-group",
              },
            ],
          },
          {
            component: CNavGroup,
            name: "Manajemen",
            to: "/manajemen",
            items: [
              {
                component: CNavItem,
                name: "Komisaris",
                to: "/manajemen/komisaris",
              },
              {
                component: CNavItem,
                name: "Direksi",
                to: "/manajemen/direksi",
              },
            ],
          },
          {
            component: CNavItem,
            name: "Mitra",
            to: "/mitra",
          },
          {
            component: CNavGroup,
            name: "Anak Perusahaan",
            to: "/anak-perusahaan",
            items: [
              {
                component: CNavItem,
                name: "Anak Perusahaan",
                to: "/anak-perusahaan",
              },
              {
                component: CNavItem,
                name: "Wilayah Anak Perusahaan",
                to: "/wilayah-anak-perusahaan",
              },
            ],
          },
          {
            component: CNavItem,
            name: "Struktur Organisasi",
            to: "/struktur-organisasi",
          },
          {
            component: CNavItem,
            name: "Sertifikasi & Penghargaan",
            to: "/awards",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "GCG",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Pages GCG",
            to: "/gcg-pages",
          },
          {
            component: CNavItem,
            name: "File GCG",
            to: "/gcg-file",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "PPID",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Pages PPID",
            to: "/ppid-pages",
          },
          {
            component: CNavItem,
            name: "File PPID",
            to: "/ppid-file",
          },
          {
            component: CNavItem,
            name: "Submenu PPID",
            to: "/ppid-Submenu",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Layanan",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Jenis Layanan",
            to: "/jenis-layanan",
          },
          {
            component: CNavItem,
            name: "Layanan",
            to: "/layanan",
          },
          {
            component: CNavItem,
            name: "Slideshow Layanan",
            to: "/slideshow-layanan",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Fasilitas/Peralatan",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Wilayah",
            to: "/wilayah-fasilitas",
          },
          {
            component: CNavItem,
            name: "Jenis",
            to: "/jenis-fasilitas",
          },
          {
            component: CNavItem,
            name: "Category",
            to: "/category-fasilitas",
          },
          {
            component: CNavItem,
            name: "Detail",
            to: "/detail-fasilitas",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Hubungan Investor",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Category",
            to: "/category-hubungan-investor",
          },
          {
            component: CNavItem,
            name: "Laporan",
            to: "/hubungan-investor",
          },
        ],
      },
      {
        component: CNavGroup,
        name: "Publikasi",
        to: "/",
        items: [
          {
            component: CNavItem,
            name: "Publikasi Media & TJSL",
            to: "/publikasi",
          },
          {
            component: CNavItem,
            name: "Category List",
            to: "/category-list",
          },
        ],
      },
      {
        component: CNavItem,
        name: "Tjsl",
        to: "/tjsl",
      },
      {
        component: CNavItem,
        name: "Kontak Kami",
        to: "/kontak-kami",
      },
      {
        component: CNavItem,
        name: "Footer",
        to: "/footer",
      },
      {
        component: CNavItem,
        name: "Footer Url Img",
        to: "/footer-url-img",
      },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: 'Template Core UI',
  //   to: '/',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Dashboard',
  //       to: '/dashboard',
  //       icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  //       badge: {
  //         color: 'info',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: CNavTitle,
  //       name: 'Theme',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Colors',
  //       to: '/theme/colors',
  //       icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Typography',
  //       to: '/theme/typography',
  //       icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavTitle,
  //       name: 'Components',
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Base',
  //       to: '/base',
  //       icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Accordion',
  //           to: '/base/accordion',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Breadcrumb',
  //           to: '/base/breadcrumbs',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Cards',
  //           to: '/base/cards',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Carousel',
  //           to: '/base/carousels',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Collapse',
  //           to: '/base/collapses',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'List group',
  //           to: '/base/list-groups',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Navs & Tabs',
  //           to: '/base/navs',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Pagination',
  //           to: '/base/paginations',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Placeholders',
  //           to: '/base/placeholders',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Popovers',
  //           to: '/base/popovers',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Progress',
  //           to: '/base/progress',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Spinners',
  //           to: '/base/spinners',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Tables',
  //           to: '/base/tables',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Tooltips',
  //           to: '/base/tooltips',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Buttons',
  //       to: '/buttons',
  //       icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Buttons',
  //           to: '/buttons/buttons',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Buttons groups',
  //           to: '/buttons/button-groups',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Dropdowns',
  //           to: '/buttons/dropdowns',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Forms',
  //       icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Form Control',
  //           to: '/forms/form-control',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Select',
  //           to: '/forms/select',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Checks & Radios',
  //           to: '/forms/checks-radios',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Range',
  //           to: '/forms/range',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Input Group',
  //           to: '/forms/input-group',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Floating Labels',
  //           to: '/forms/floating-labels',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Layout',
  //           to: '/forms/layout',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Validation',
  //           to: '/forms/validation',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Charts',
  //       to: '/charts',
  //       icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Icons',
  //       icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'CoreUI Free',
  //           to: '/icons/coreui-icons',
  //           badge: {
  //             color: 'success',
  //             text: 'NEW',
  //           },
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'CoreUI Flags',
  //           to: '/icons/flags',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'CoreUI Brands',
  //           to: '/icons/brands',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Notifications',
  //       icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Alerts',
  //           to: '/notifications/alerts',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Badges',
  //           to: '/notifications/badges',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Modal',
  //           to: '/notifications/modals',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Toasts',
  //           to: '/notifications/toasts',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Widgets',
  //       to: '/widgets',
  //       icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  //       badge: {
  //         color: 'info',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       component: CNavTitle,
  //       name: 'Extras',
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Pages',
  //       icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Login',
  //           to: '/login',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Register',
  //           to: '/register',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Error 404',
  //           to: '/404',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Error 500',
  //           to: '/500',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Docs',
  //       href: 'https://coreui.io/react/docs/templates/installation/',
  //       icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  //     },
  //   ]
  // },
];

export default _nav;
