import React from "react";

const Pages = React.lazy(() => import("./views/landingPage/pages"));
const Slideshow = React.lazy(() => import("./views/landingPage/slideshow"));
const CrudSlideshow = React.lazy(() =>
  import("./views/landingPage/slideshow/crud")
);
const Footer = React.lazy(() => import("./views/landingPage/footer"));
const FooterUrlImg = React.lazy(() =>
  import("./views/landingPage/footerurlimg")
);
const CrudFooterUrlImg = React.lazy(() =>
  import("./views/landingPage/footerurlimg/crud")
);
const AnakPerusahaan = React.lazy(() =>
  import("./views/landingPage/anakperusahaan")
);
const CrudAnakPerusahaan = React.lazy(() =>
  import("./views/landingPage/anakperusahaan/crud")
);
const TentangKami = React.lazy(() =>
  import("./views/landingPage/profil/tentangkami")
);
const CrudTentangKami = React.lazy(() =>
  import("./views/landingPage/profil/tentangkami/crud")
);
const Testimoni = React.lazy(() => import("./views/landingPage/testimoni"));
const CrudTestimoni = React.lazy(() =>
  import("./views/landingPage/testimoni/crud")
);
const Quotes = React.lazy(() => import("./views/landingPage/quotes"));
const CrudQuotes = React.lazy(() => import("./views/landingPage/quotes/crud"));
const Mitra = React.lazy(() => import("./views/landingPage/profil/mitra"));
const CrudMitra = React.lazy(() =>
  import("./views/landingPage/profil/mitra/crud")
);
const OurGroup = React.lazy(() =>
  import("./views/landingPage/profil/ourgroup")
);
const CrudOurGroup = React.lazy(() =>
  import("./views/landingPage/profil/ourgroup/crud")
);
const CoreValue = React.lazy(() =>
  import("./views/landingPage/profil/corevalue")
);
const CrudCoreValue = React.lazy(() =>
  import("./views/landingPage/profil/corevalue/crud")
);
const CoreValueDetail = React.lazy(() =>
  import("./views/landingPage/profil/corevaluedetail")
);
const CrudCoreValueDetail = React.lazy(() =>
  import("./views/landingPage/profil/corevaluedetail/crud")
);
const ManajemenKomisaris = React.lazy(() =>
  import("./views/landingPage/profil/manajemen/komisaris")
);
const CrudManajemenKomisaris = React.lazy(() =>
  import("./views/landingPage/profil/manajemen/komisaris/crud")
);
const ManajemenDireksi = React.lazy(() =>
  import("./views/landingPage/profil/manajemen/direksi")
);
const CrudManajemenDireksi = React.lazy(() =>
  import("./views/landingPage/profil/manajemen/direksi/crud")
);
const StrukturOrganisasi = React.lazy(() =>
  import("./views/landingPage/profil/strukturorganisasi")
);
const CrudStrukturOrganisasi = React.lazy(() =>
  import("./views/landingPage/profil/strukturorganisasi/crud")
);
const Award = React.lazy(() =>
  import("./views/landingPage/profil/sertifikasi")
);
const CrudSertifikasi = React.lazy(() =>
  import("./views/landingPage/profil/sertifikasi/crud")
);
const Publikasi = React.lazy(() => import("./views/landingPage/publikasi"));
const CrudPublikasi = React.lazy(() =>
  import("./views/landingPage/publikasi/crud")
);
const HubunganInvestor = React.lazy(() =>
  import("./views/landingPage/hubunganinvestor")
);
const CrudHubunganInvestor = React.lazy(() =>
  import("./views/landingPage/hubunganinvestor/crud")
);
const Tjsl = React.lazy(() => import("./views/landingPage/tjsl"));
const CrudTjsl = React.lazy(() => import("./views/landingPage/tjsl/crud"));
const JenisLayanan = React.lazy(() =>
  import("./views/landingPage/layanan/jenislayanan")
);
const CrudJenisLayanan = React.lazy(() =>
  import("./views/landingPage/layanan/jenislayanan/crud")
);
const Layanan = React.lazy(() => import("./views/landingPage/layanan/layanan"));
const CrudLayanan = React.lazy(() =>
  import("./views/landingPage/layanan/layanan/crud")
);
const SlideshowLayanan = React.lazy(() =>
  import("./views/landingPage/layanan/slideshowlayanan")
);
const CrudSlideshowLayanan = React.lazy(() =>
  import("./views/landingPage/layanan/slideshowlayanan/crud")
);
const PagesGcg = React.lazy(() =>
  import("./views/landingPage/goodcorporate/pages")
);
const CrudPagesGcg = React.lazy(() =>
  import("./views/landingPage/goodcorporate/pages/crud")
);
const FileGcg = React.lazy(() =>
  import("./views/landingPage/goodcorporate/file")
);
const CrudFileGcg = React.lazy(() =>
  import("./views/landingPage/goodcorporate/file/crud")
);
const Gcg = React.lazy(() => import("./views/landingPage/gcg"));
const CrudGcg = React.lazy(() => import("./views/landingPage/gcg/crud"));


const PagesPpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/pages")
);
const CrudPagesPpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/pages/crud")
);
const FilePpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/file")
);
const CrudFilePpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/file/crud")
);
const CrudTitlePpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/file/crud/title.js")
);
const SubmenuPpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/Submenu")
);
const CrudSubmenuPpid = React.lazy(() =>
  import("./views/landingPage/ppidnew/Submenu/crud")
);
const Ppid = React.lazy(() => import("./views/landingPage/ppid"));
const CrudPpid = React.lazy(() => import("./views/landingPage/ppid/crud"));

const WilayahFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/wilayah")
);
const CrudWilayahFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/wilayah/crud")
);
const JenisFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/jenis")
);
const CrudJenisFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/jenis/crud")
);
const CategoryFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/category")
);
const CrudCategoryFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/category/crud")
);
const DetailFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/detail")
);
const CrudDetailFasilitas = React.lazy(() =>
  import("./views/landingPage/fasilitasperalatan/detail/crud")
);
const CategoryList = React.lazy(() =>
  import("./views/landingPage/publikasi/category")
);
const CrudCategoryList = React.lazy(() =>
  import("./views/landingPage/publikasi/category/crud")
);
const KontakKami = React.lazy(() => import("./views/landingPage/kontakkami"));
const CategoryHubunganInvestor = React.lazy(() =>
  import("./views/landingPage/hubunganinvestor/category")
);
const CrudCategoryHubunganInvestor = React.lazy(() =>
  import("./views/landingPage/hubunganinvestor/category/crud")
);
const WilayahAnakPerusahaan = React.lazy(() =>
  import("./views/landingPage/anakperusahaan/wilayah")
);
const CrudWilayahAnakPerusahaan = React.lazy(() =>
  import("./views/landingPage/anakperusahaan/wilayah/crud")
);
const Popup = React.lazy(() => import("./views/landingPage/popup"));
const CrudPopup = React.lazy(() => import("./views/landingPage/popup/crud"));

const routesPsl = [
  { path: "/pages", name: "Pages", element: Pages },
  { path: "/slideshow", name: "Slideshow", element: Slideshow },
  { path: "/slideshow/add", name: "Add Slideshow", element: CrudSlideshow },
  {
    path: "/slideshow/edit/:id",
    name: "Edit Slideshow",
    element: CrudSlideshow,
  },
  { path: "/footer", name: "Footer", element: Footer },
  { path: "/footer-url-img", name: "Footer Url Img", element: FooterUrlImg },
  {
    path: "/footer-url-img/add",
    name: "Add Footer Url Img",
    element: CrudFooterUrlImg,
  },
  {
    path: "/footer-url-img/edit/:id",
    name: "Edit Footer Url Img",
    element: CrudFooterUrlImg,
  },
  {
    path: "/anak-perusahaan",
    name: "Anak Perusahaan",
    element: AnakPerusahaan,
  },
  {
    path: "/anak-perusahaan/add",
    name: "Add Anak Perusahaan",
    element: CrudAnakPerusahaan,
  },
  {
    path: "/anak-perusahaan/edit/:id",
    name: "Edit Anak Perusahaan",
    element: CrudAnakPerusahaan,
  },
  { path: "/tentang-kami", name: "Tentang Kami", element: TentangKami },
  {
    path: "/tentang-kami/add",
    name: "Add Tentang Kami",
    element: CrudTentangKami,
  },
  {
    path: "/tentang-kami/edit/:id",
    name: "Edit Tentang Kami",
    element: CrudTentangKami,
  },
  { path: "/our-group", name: "Our Group", element: OurGroup },
  { path: "/our-group/add", name: "Add Our Group", element: CrudOurGroup },
  {
    path: "/our-group/edit/:id",
    name: "Edit Our Group",
    element: CrudOurGroup,
  },
  { path: "/testimoni", name: "Testimoni", element: Testimoni },
  { path: "/testimoni/add", name: "Add Testimoni", element: CrudTestimoni },
  {
    path: "/testimoni/edit/:id",
    name: "Edit Testimoni",
    element: CrudTestimoni,
  },
  { path: "/quotes", name: "Pesan BOD", element: Quotes },
  { path: "/quotes/add", name: "Add Quotes", element: CrudQuotes },
  { path: "/quotes/edit/:id", name: "Edit Quotes", element: CrudQuotes },
  { path: "/mitra", name: "Mitra", element: Mitra },
  { path: "/mitra/add", name: "Add Mitra", element: CrudMitra },
  { path: "/mitra/edit/:id", name: "Edit Mitra", element: CrudMitra },
  { path: "/core-value", name: "Core Value", element: CoreValue },
  { path: "/core-value/add", name: "Add Core Value", element: CrudCoreValue },
  {
    path: "/core-value/edit/:id",
    name: "Edit Core Value",
    element: CrudCoreValue,
  },
  { path: "/core-value-detail", name: "Core Value", element: CoreValueDetail },
  {
    path: "/core-value-detail/add",
    name: "Add Core Value",
    element: CrudCoreValueDetail,
  },
  {
    path: "/core-value-detail/edit/:id",
    name: "Edit Core Value",
    element: CrudCoreValueDetail,
  },
  {
    path: "/manajemen/komisaris",
    name: "Manajemen Komisaris",
    element: ManajemenKomisaris,
  },
  {
    path: "/manajemen/komisaris/add",
    name: "Add Manajemen Komisaris",
    element: CrudManajemenKomisaris,
  },
  {
    path: "/manajemen/komisaris/edit/:id",
    name: "Edit Manajemen Komisaris",
    element: CrudManajemenKomisaris,
  },
  {
    path: "/manajemen/direksi",
    name: "Manajemen Direksi",
    element: ManajemenDireksi,
  },
  {
    path: "/manajemen/direksi/add",
    name: "Add Manajemen Direksi",
    element: CrudManajemenDireksi,
  },
  {
    path: "/manajemen/direksi/edit/:id",
    name: "Edit Manajemen Direksi",
    element: CrudManajemenDireksi,
  },
  {
    path: "/struktur-organisasi",
    name: "Struktur Organisasi",
    element: StrukturOrganisasi,
  },
  {
    path: "/struktur-organisasi/add",
    name: "Add Struktur Organisasi",
    element: CrudStrukturOrganisasi,
  },
  {
    path: "/struktur-organisasi/edit/:id",
    name: "Edit Struktur Organisasi",
    element: CrudStrukturOrganisasi,
  },
  { path: "/awards", name: "Sertifikasi & Penghargaan", element: Award },
  {
    path: "/awards/add",
    name: " Add Sertifikasi & Penghargaan",
    element: CrudSertifikasi,
  },
  {
    path: "/awards/edit/:id",
    name: "Edit Sertifikasi & Penghargaan",
    element: CrudSertifikasi,
  },
  { path: "/hubungan-investor", name: "Laporan", element: HubunganInvestor },
  {
    path: "/hubungan-investor/add",
    name: "Add Laporan",
    element: CrudHubunganInvestor,
  },
  {
    path: "/hubungan-investor/edit/:id",
    name: "Edit Laporan",
    element: CrudHubunganInvestor,
  },
  {
    path: "/publikasi",
    name: "Publikasi Media, Pers & TJSL",
    element: Publikasi,
  },
  { path: "/publikasi/add", name: "Add Publikasi", element: CrudPublikasi },
  {
    path: "/publikasi/edit/:id",
    name: "Edit Publikasi",
    element: CrudPublikasi,
  },
  { path: "/tjsl", name: "TJSL", element: Tjsl },
  { path: "/tjsl/add", name: "Add TJSL", element: CrudTjsl },
  { path: "/tjsl/edit/:id", name: "Edit TJSL", element: CrudTjsl },
  { path: "/jenis-layanan", name: "Jenis Layanan", element: JenisLayanan },
  {
    path: "/jenis-layanan/add",
    name: "Add Jenis Layanan",
    element: CrudJenisLayanan,
  },
  {
    path: "/jenis-layanan/edit/:id",
    name: "Edit Jenis Layanan",
    element: CrudJenisLayanan,
  },
  { path: "/layanan", name: "Layanan", element: Layanan },
  { path: "/layanan/add", name: "Add Layanan", element: CrudLayanan },
  { path: "/layanan/edit/:id", name: "Edit Layanan", element: CrudLayanan },
  {
    path: "/slideshow-layanan",
    name: "Slideshow Layanan",
    element: SlideshowLayanan,
  },
  {
    path: "/slideshow-layanan/add",
    name: "Add Slideshow Layanan",
    element: CrudSlideshowLayanan,
  },
  {
    path: "/slideshow-layanan/edit/:id",
    name: "Edit Slideshow Layanan",
    element: CrudSlideshowLayanan,
  },
  { path: "/gcg-pages", name: "Pages GCG", element: PagesGcg },
  { path: "/gcg-pages/add", name: "Add Pages GCG", element: CrudPagesGcg },
  {
    path: "/gcg-pages/edit/:id",
    name: "Edit Pages GCG",
    element: CrudPagesGcg,
  },
  { path: "/gcg-file", name: "File GCG", element: FileGcg },
  { path: "/gcg-file/add", name: "Add File GCG", element: CrudFileGcg },
  { path: "/gcg-file/edit/:id", name: "Edit File GCG", element: CrudFileGcg },
  { path: "/gcg", name: "GCG", element: Gcg },
  { path: "/gcg/add", name: "Add GCG", element: CrudGcg },
  { path: "/gcg/edit/:id", name: "Edit Hubungan Investor", element: CrudGcg },
  { path: "/ppid-pages", name: "Pages PPID", element: PagesPpid },
  { path: "/ppid-pages/add", name: "Add Pages PPID", element: CrudPagesPpid },
  {
    path: "/ppid-pages/edit/:id",
    name: "Edit Pages PPID",
    element: CrudPagesPpid,
  },
  { path: "/ppid-file", name: "File PPID", element: FilePpid },
  { path: "/ppid-file/add", name: "Add File PPID", element: CrudFilePpid },
  { path: "/ppid-file/edit/:id", name: "Edit File PPID", element: CrudFilePpid },
  { path: "/ppid-title/add", name: "Add Title PPID", element: CrudTitlePpid },
  { path: "/ppid-title/edit/:id", name: "Edit Title PPID", element: CrudTitlePpid },
  { path: "/ppid-submenu", name: "Submenu PPID", element: SubmenuPpid },
  { path: "/ppid-submenu/add", name: "Add Submenu PPID", element: CrudSubmenuPpid },
  { path: "/ppid-submenu/edit/:id", name: "Edit Submenu PPID", element: CrudSubmenuPpid },
  {
    path: "/wilayah-fasilitas",
    name: "Wilayah Fasilitas",
    element: WilayahFasilitas,
  },
  {
    path: "/wilayah-fasilitas/add",
    name: "Add Wilayah Fasilitas",
    element: CrudWilayahFasilitas,
  },
  {
    path: "/wilayah-fasilitas/edit/:id",
    name: "Edit Wilayah Fasilitas",
    element: CrudWilayahFasilitas,
  },
  {
    path: "/jenis-fasilitas",
    name: "Jenis Fasilitas",
    element: JenisFasilitas,
  },
  {
    path: "/jenis-fasilitas/add",
    name: "Add Jenis Fasilitas",
    element: CrudJenisFasilitas,
  },
  {
    path: "/jenis-fasilitas/edit/:id",
    name: "Edit Jenis Fasilitas",
    element: CrudJenisFasilitas,
  },
  {
    path: "/category-fasilitas",
    name: "Category Fasilitas",
    element: CategoryFasilitas,
  },
  {
    path: "/category-fasilitas/add",
    name: "Add Category Fasilitas",
    element: CrudCategoryFasilitas,
  },
  {
    path: "/category-fasilitas/edit/:id",
    name: "Edit Category Fasilitas",
    element: CrudCategoryFasilitas,
  },
  {
    path: "/detail-fasilitas",
    name: "Detail Fasilitas",
    element: DetailFasilitas,
  },
  {
    path: "/detail-fasilitas/add",
    name: "Add Detail Fasilitas",
    element: CrudDetailFasilitas,
  },
  {
    path: "/detail-fasilitas/edit/:id",
    name: "Edit Detail Fasilitas",
    element: CrudDetailFasilitas,
  },
  { path: "/category-list", name: "Kategori Publikasi", element: CategoryList },
  {
    path: "/category-list/add",
    name: "Add Category",
    element: CrudCategoryList,
  },
  {
    path: "/category-list/edit/:id",
    name: "Edit Category",
    element: CrudCategoryList,
  },
  { path: "/kontak-kami", name: "Kontak Kami", element: KontakKami },
  {
    path: "/category-hubungan-investor",
    name: "Category Laporan",
    element: CategoryHubunganInvestor,
  },
  {
    path: "/category-hubungan-investor/add",
    name: "Add Category Laporan",
    element: CrudCategoryHubunganInvestor,
  },
  {
    path: "/category-hubungan-investor/edit/:id",
    name: "Edit Category Laporan",
    element: CrudCategoryHubunganInvestor,
  },
  {
    path: "/wilayah-anak-perusahaan",
    name: "Wilayah Anak Perusahaan",
    element: WilayahAnakPerusahaan,
  },
  {
    path: "/wilayah-anak-perusahaan/add",
    name: "Add Wilayah Anak Perusahaan",
    element: CrudWilayahAnakPerusahaan,
  },
  {
    path: "/wilayah-anak-perusahaan/edit/:id",
    name: "Edit Wilayah Anak Perusahaan",
    element: CrudWilayahAnakPerusahaan,
  },
  { path: "/popup", name: "Pop Up", element: Popup },
  { path: "/popup/add", name: "Pop Up", element: CrudPopup },
  { path: "/popup/edit/:id", name: "Pop Up", element: CrudPopup },
];

export default routesPsl;
