import App from "App";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routesTemplate from "routes";
import routesPsl from "routesPsl";
import Home from "views/home";
import Login from "views/login";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const Router = () => {
    return (
        <>
            <BrowserRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route path="/" element={<App />}>
                            <Route index element={<Home />} />
                            {routesPsl.map((route, idx) => {
                                return (
                                    route.element && (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            element={<route.element name={route.name} />}
                                        />
                                    )
                                )
                            })}

                            {routesTemplate.map((route, idx) => {
                                return (
                                    route.element && (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            element={<route.element />}
                                        />
                                    )
                                )
                            })}
                        </Route>
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>
    )
}
export default Router;