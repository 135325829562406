import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import formReducers from "./form/formReducers";
import globalReducers from "./global/globalReducers";
import axios from "axios";
import { setErrorResponse, setLoader } from "./global/globalActions";
import { getCookie, setCookie } from "utils/Cookies";
import { responseMessage, responseResult } from "utils/ResponseStatus";
import ApiGet from "api/ApiGet";

const store = configureStore({
    reducer: combineReducers({
        form: formReducers,
        global: globalReducers
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// var key
// if (getCookie('ApiKey')) {
//     key = getCookie('ApiKey')
// } else {
//     ApiGet.actions.GetApiKey()
//         .then((res) => {
//             setCookie('ApiKey', responseResult(res))
//             key = getCookie('ApiKey')
//         })
// }

// Add a request interceptor
axios.interceptors.request.use(async function (config) {
    let detailLogin = store.getState().global.detailLogin
    await store.dispatch(setLoader(true))
    // if (key?.ApiKey) {
    //     config.headers.Authorization = `${key?.ApiKey}`;
    // }
    config.headers.Authorization = process.env.REACT_APP_ApiKey
    if (detailLogin) {
        config.headers["Session-Login"] = detailLogin.username;
    }
    await store.dispatch(setLoader(true))
    return config;
}, async function (error) {
    // Do something with request error
    await store.dispatch(setLoader(false))
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log(`[INTERCEPTORS] ${response?.request?.responseURL}:`, response);
    await store.dispatch(setLoader(false))
    return response;
}, async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    // console.log(`[INTERCEPTORS]:`, responseMessage(error.response.data));
    await store.dispatch(setErrorResponse(responseMessage(error?.response?.data)))
    await store.dispatch(setLoader(false))
    return Promise.reject(error);
});

export default store;
