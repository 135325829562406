import { createSlice } from "@reduxjs/toolkit";


export const fromOptions = createSlice({
    name: "@@Form",
    initialState: {
        field: {},
        requiredField: []
    },
    reducers: {
        Destroy: (state, action) => {
            // state.field = {};
            return state;
        },
        Change: (state, action) => {
            state.field = {
                ...state.field,
                [action.payload.name]: action.payload.value
            };
        },
        Required: (state, action) => {
            state.requiredField = [
                ...state.requiredField,
                action.payload.value
            ];
        },
    },
});

export const { Destroy, Change, Required } = fromOptions.actions;

export default fromOptions.reducer;