const APIS = window.location.protocol + '//' + window.location.hostname + ':' + process.env.REACT_APP_API_PORT;

const API = process.env.REACT_APP_API_PROD;
const APIDEV = process.env.REACT_APP_API_DEV + ':' + process.env.REACT_APP_API_PORT;


export const clientKey = process.env.REACT_APP_CLIENT_KEY;
export const SALT = process.env.REACT_APP_SALT;

export const uAPI = API //for prod api
// export const uAPI = APIDEV //for dev api
// export const uAPI = APIS // for global