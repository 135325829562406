import { getCookie } from "utils/Cookies";
import { ERRORRESPONSE, ISLOADER, ISLOGIN, LOGINRESULT, RESETDATATABLES, RESETERRORRESPONSE, RESETGLOBAL, SETDATATABLES, SETSIDEBAR } from "./globalTypes";

const initialState = {
    isLoader: false,
    isLogin: getCookie('rememberMe') ? getCookie('rememberMe') : false,
    errorResponse: null,
    detailLogin: getCookie('detailLogin') ? JSON.parse(getCookie('detailLogin')) : null,
    sidebar: {
        sidebarShow: true,
        sidebarUnfoldable: false
    },
    dataTables: null
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ISLOADER:
            return {
                ...state,
                isLoader: payload,
            };
        case ISLOGIN:
            return {
                ...state,
                isLogin: payload,
            };
        case ERRORRESPONSE:
            return {
                ...state,
                errorResponse: payload,
            };
        case RESETERRORRESPONSE:
            return {
                ...state,
                errorResponse: null,
            };
        case LOGINRESULT:
            return {
                ...state,
                detailLogin: payload,
            };
        case SETSIDEBAR:
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...payload,
                }
            };
        case RESETGLOBAL:
            return initialState;

        case SETDATATABLES:
            return {
                ...state,
                dataTables: payload,
            };
        case RESETDATATABLES:
            return {
                ...state,
                dataTables: null,
            };

        default:
            return state;
    }
}

export default reducer;