import React, { useState } from "react";
import {
    CAlert,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CFormCheck,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import useRedux from "redux/useRedux";
import { formChange } from "redux/form/formActions";
import { loginResult, resetErrorResponse, setLogin } from "redux/global/globalActions";
import { Navigate } from "react-router-dom";
import Form from "components/reusable/FormInput/Form";
import { responseResult } from "utils/ResponseStatus";
import { setCookie, setCookieNotRemember } from "utils/Cookies";
import ApiPost from "api/ApiPost";

const Login = () => {
    const {
        dispatch,
        form: {
            field
        },
        global
    } = useRedux();

    const [rememberMe, setRememberMe] = useState(false);

    const onChange = (e) => {
        let { name, value } = e.target;
        dispatch(formChange(name, value))
    }

    const onChangeRemember = (e) => {
        setRememberMe(e.target.checked)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        await ApiPost.actions.Login(field)
            .then(async response => {
                if (response) {
                    await dispatch(setLogin(true));
                    await dispatch(loginResult(responseResult(response)));
                    if (rememberMe) {
                        await setCookie('rememberMe', rememberMe)
                        await setCookie('detailLogin', JSON.stringify(responseResult(response)))
                    } else {
                        await setCookieNotRemember('rememberMe', rememberMe)
                        await setCookieNotRemember('detailLogin', JSON.stringify(responseResult(response)))
                    }
                }
            })
    }

    if (global.isLogin === true && global.detailLogin !== null) {
        return <Navigate replace to="/" />
    }

    return (
        <>
            <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md={8}>
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <Form onSubmit={onSubmit} customButton={
                                            <CRow>
                                                <CCol xs={6}>
                                                    {
                                                        global.isLoader ? (
                                                            <button className="btn btn-primary" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </button>
                                                        ) : (
                                                            <button type="submit" className="btn btn-primary px-4">Login</button>
                                                        )
                                                    }
                                                </CCol>
                                            </CRow>
                                        } >
                                            <h1>Login</h1>
                                            <p className="text-medium-emphasis">Sign In to your account</p>
                                            {
                                                global.errorResponse && (
                                                    <CAlert
                                                        color="danger"
                                                        dismissible
                                                        onClose={() => {
                                                            dispatch(resetErrorResponse())
                                                        }}
                                                    >
                                                        <strong>Oops!</strong> {global.errorResponse}
                                                    </CAlert>
                                                    // <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                                    //     <strong>Oops!</strong> {global.errorResponse}
                                                    //     <button type="button" class="btn-close" data-coreui-dismiss="alert" aria-label="Close"></button>
                                                    // </div>
                                                )
                                            }

                                            <CInputGroup className="mb-3">
                                                <CInputGroupText>
                                                    <CIcon icon={cilUser} />
                                                </CInputGroupText>
                                                <CFormInput
                                                    name="username"
                                                    value={field?.username ? field?.username : ''}
                                                    onChange={onChange}
                                                    placeholder="Username" autoComplete="username"
                                                    required
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupText>
                                                    <CIcon icon={cilLockLocked} />
                                                </CInputGroupText>
                                                <CFormInput
                                                    name="password"
                                                    value={field?.password ? field?.password : ''}
                                                    onChange={onChange}
                                                    type="password"
                                                    placeholder="Password"
                                                    autoComplete="current-password"
                                                    required
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CFormCheck id="flexCheckDefault" label="Remember me"
                                                    checked={rememberMe}
                                                    name="remember"
                                                    onChange={onChangeRemember} />
                                            </CInputGroup>
                                        </Form>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        </>
    )
}
export default Login